<template>
  <div
    v-if="!loading"
    class="app-content content "
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <div class="avatar avatar-custom avatar-xl float-start">
                <img
                  :src="icon_path ? icon_path : require('@/assets/images/avatars/user_default.png')"
                  alt="avatar"
                  class="img-fluid"
                >
              </div>
              <h2 class="content-header-title  mb-0">
                {{ user.name }}
                <span>{{ user.areas ? `${user.areas.name}` : '' }}</span>
                <template v-if="user.current_institution">
                  <span>{{ user.current_institution.code }}</span>
                  <!-- <span>{{ user.areas && user.areas[0] ? user.areas[0].code : '' }}</span> -->
                  <span>{{ user.current_institution.pivot && user.current_institution.pivot.department_aux ? user.current_institution.pivot.department_aux.name : '' }}</span>
                </template>
              </h2>
            </div>
          </div>

        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div
              class="btn-group"
            >
              <b-dropdown
                variant="button"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <button
                    id="dropdownMenuButton"
                    class="btn btn-primary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Quick actions <i data-feather="chevron-down" />
                  </button>
                </template>
                <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                  <template v-if="loggedUser.roles.includes('super-admin')">
                    <h6 class="dropdown-header">
                      Admin
                    </h6>
                    <b-dropdown-item @click="openFileInput">
                      <a
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasEditPhoto"
                        aria-controls="offcanvasEditPhoto"
                      >Edit photo</a>
                    </b-dropdown-item>
                    <div class="dropdown-divider" />
                  </template>
                  <b-dropdown-item @click="$router.push({name: 'researcher.personal-data.index'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#editCV"
                      aria-controls="editCV"
                    >Edit CV</a>
                  </b-dropdown-item>
                  <b-dropdown-item @click="$router.push({name: 'researcher.scientific-production-report.index'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#scientficProductionReport"
                      aria-controls="scientficProductionReport"
                    >Scientific Production Report</a>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="user.CVFile.length > 0 && !hide"
                    @click="viewCV"
                  >
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#abridgedCV"
                      aria-controls="abridgedCV"
                    >View abridged CV</a>
                  </b-dropdown-item>
                  <div class="dropdown-divider" />
                  <b-dropdown-item @click="$router.push({name: 'researcher.edit-access'})">
                    <a
                      data-bs-toggle="offcanvas"
                      data-bs-target="#editCV"
                      aria-controls="editCV"
                    >Edit intranet access</a>
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card card--profile-alerts">
              <div class="card-body">
                <template
                  v-if="!loading"
                >
                  <div class="row">
                    <div class="col-sm-6 card--profile-alerts-calendar">
                      <input
                        ref="fileInput"
                        type="file"
                        style="display: none"
                        @change="handleFileChange"
                      >
                      <h3><i
                        data-feather="book"
                        class="w-icon-24"
                      /> Short biography</h3>
                      <hr>
                      <p v-html="user.short_biography" />
                    </div>
                    <div class="col-sm-6 card--profile-alerts-alarms">
                      <h3><i
                        data-feather="mic"
                        class="w-icon-24"
                      /> Research interest</h3>
                      <hr>
                      <p v-html="user.research_interest" />
                    </div>
                  </div>
                </template>
                <div
                  v-if="loading"
                >
                  <div
                    class="loading"
                  >
                    <div class="effect-1 effects" />
                    <div class="effect-2 effects" />
                    <div class="effect-3 effects" />
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body content-body--senior-call">
              <div class="row">
                <div class="col-6">
                  <div class="card card--profile-contact-information">
                    <div class="card-body">
                      <template
                        v-if="!loading"
                      >
                        <div class="row">
                          <div class="col">
                            <div class="clearfix">
                              <h3 class="float-start">
                                Contact information
                              </h3>
                            </div>
                            <hr>
                          </div>
                        </div>
                        <div
                          class="row"
                        >
                          <div class="col-sm-3">
                            <p>Personal phone <a
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Copy phone"
                              @click="copyToClipboard(user.phone)"
                            ><i data-feather="copy" /></a> <strong><a :href="`tel:${user.phone}`">{{ user.phone ? user.phone : user.fix_phone }}</a></strong></p>
                          </div>
                          <template v-if="user.real_professional_background">
                            <div class="col-sm-3">
                              <p>Work Email <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy email"
                                @click="copyToClipboard(user.real_professional_background.professional_email)"
                              ><i data-feather="copy" /></a> <strong><a :href="`mailto:${user.real_professional_background.professional_email}`">{{ user.real_professional_background.professional_email }}</a></strong></p>
                            </div>
                            <div class="col-sm-3">
                              <p>Work phone <a
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy phone"
                                @click="copyToClipboard(user.real_professional_background.phone)"
                              ><i data-feather="copy" /></a> <strong><a :href="`tel:${user.real_professional_background.phone}`">{{ user.real_professional_background.phone }}</a></strong></p>
                            </div>
                            <div class="col-sm-3">
                              <p>Work Address <a
                                v-if="user.real_professional_background && user.real_professional_background.department"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy address"
                                @click="copyToClipboard(`${user.real_professional_background.department.address}, ${user.real_professional_background.department.city} ${user.real_professional_background.department.postal_code}`)"
                              ><i data-feather="copy" /></a>
                                <strong>
                                  <template v-if="user.real_professional_background && user.real_professional_background.department && user.real_professional_background.department.id">
                                    {{ user.real_professional_background.address_2 }}<br>
                                    {{ user.real_professional_background.department.postal_code }}
                                    {{ user.real_professional_background.department.city }}
                                  </template>
                                  <template v-else>
                                    {{ user.real_professional_background.institution.address }}<br>
                                    {{ user.real_professional_background.institution.postal_code }}
                                    {{ user.real_professional_background.institution.city }}
                                  </template>
                                </strong></p>
                            </div>
                          </template>
                        </div>
                      </template>
                      <div
                        v-if="loading"
                      >
                        <div
                          class="loading"
                        >
                          <div class="effect-1 effects" />
                          <div class="effect-2 effects" />
                          <div class="effect-3 effects" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card card--profile-contact-information">
                    <div class="card-body">
                      <template
                        v-if="!loading"
                      >
                        <div class="row">
                          <div class="col">
                            <div class="clearfix">
                              <h3 class="float-start">
                                More information
                              </h3>
                              <a
                                v-if="user.enter_convo_id && !hide"
                                href="#"
                                class="float-end"
                                @click="showEvaluation"
                              >View ICREA candidancy</a>
                            </div>
                            <hr>
                            <div class="row">
                              <div class="col-sm-2">
                                <p>Next promotion <a
                                  href="#"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="go to promotion call 2026"
                                >
                                <!-- <i data-feather="link" /> -->
                                </a> <strong>{{ user.next_promotion_year }}</strong></p>
                              </div>
                              <div class="col-sm-2">
                                <p>Starting date <strong>{{ user.researcher_start_date }}</strong></p> <!-- Hace referencia a la fecha de cuando se le otorgó el rol de ICREA -->
                              </div>
                              <div
                                v-if="loggedUser.roles.includes('super-admin')"
                                class="col-sm-2"
                              >
                                <p>Level <strong>WIP</strong></p>
                              </div>
                              <div
                                v-if="loggedUser.roles.includes('super-admin')"
                                class="col-sm-2"
                              >
                                <p>Birthday & age <strong>{{ user.birthday }} ({{ user.years_old }})</strong></p>
                              </div>
                            </div>
                            <hr>
                            <div class="row">
                              <div class="col-md-auto">
                                <p>Main ERC Panel  <strong>{{ user.main_ercs.level3 ? user.main_ercs.level3.code : (user.main_ercs.level2 ? user.main_ercs.level2.code : '-') }}</strong></p>
                              </div>
                              <div class="col-md-3">
                                <p class="m-0">
                                  Other ERC Panels
                                </p>
                                <div class="row">
                                  <div
                                    v-for="(aerc, index) in user.additional_ercs"
                                    :key="index"
                                    class="col-md-6"
                                  >
                                    <strong>{{ aerc.level3 ? aerc.level3.code : (aerc.level2 ? aerc.level2.code : '-') }}</strong>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-auto">
                                <p>Researcher ID (WOS):  <strong>{{ user.researcher_id }}</strong></p>
                              </div>
                              <div class="col-md-auto">
                                <p>Author ID (SCOPUS)  <strong>{{ user.author_id }}</strong></p>
                              </div>
                              <div class="col-md-auto">
                                <p>ORCID  <strong>{{ user.orcid }}</strong></p>
                              </div>
                            </div>
                            <hr>
                            <div class="row">
                              <div class="col">
                                <p>
                                  Keywords
                                  <strong>
                                    <span
                                      v-for="(keyw, index) in user.keywords"
                                      :key="index"
                                      class="badge bg-secondary me-1"
                                    >
                                      {{ keyw.word }}
                                    </span>
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div
                        v-if="loading"
                      >
                        <div
                          class="loading"
                        >
                          <div class="effect-1 effects" />
                          <div class="effect-2 effects" />
                          <div class="effect-3 effects" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="alertLoading">
              <div
                class="loading custom-loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </div>
            <div
              v-if="!alertLoading && alerts.length > 0"
              class="profile__important__notification"
            >
              <div
                v-for="alert in alerts"
                :key="alert.id"
                class="row mb-1"
              >
                <div class="col-10">
                  <span class="text-danger"><i data-feather="bell" /></span> <strong>{{ alert.title }}</strong>: {{ alert.message }}
                  <p class="text-muted">
                    <small>{{ alert.created_at }}</small>
                  </p>
                </div>
                <div class="col-2 text-right">
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Go to the notification"
                  >
                    <a
                      class="btn bg-light-secondary"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasReadNotification"
                      aria-controls="offcanvasRight"
                      @click="showAlert(alert)"
                    >
                      <i data-feather="link" />
                    </a>
                  </span>
                </div>
              </div>
            </div> -->
            <div class="row">
              <CalendarAndAlarms @showAlert="showAlert" />

              <Tasks />

              <Notifications v-if="!hide" />

              <Stats
                v-if="!loading"
                :user-id="user.id"
                :year-between="yearBetween"
                :show-all-stats="false"
                :col-size="6"
                :set-default-toggled="false"
              />

              <Timeline
                v-if="loggedUser.roles.includes('super-admin')"
              />

              <Trips />

              <FutureTrips />
            </div>

            <Traces
              v-if="loggedUser.roles.includes('super-admin')"
            />
          </div>
          <ItemInfoSheet>
            <template #info-sheet-title>
              Read notification
            </template>
            <template #info-sheet>
              <div class="offcanvas-body">
                <div class="mb-1 offcanvas-body--view--title">
                  <p>Send<br>
                    <strong>{{ active.date }}</strong></p>
                </div>
                <hr>
                <div class="mb-1 offcanvas-body--view--title">
                  <p>Subject<br>
                    <strong>{{ active.title }}</strong></p>
                </div>
                <div class="mb-1">
                  <p>Message<br>
                    <strong v-html="active.message" />
                  </p>
                </div>
                <div
                  v-if="active.file"
                  class="mb-1"
                >
                  <p>Attached file<br>
                    <a
                      :href="active.file.url"
                      title="open file"
                      target="_blank"
                    ><i data-feather="eye" /> {{ active.file.name }}</a>
                  </p>
                </div>
              </div>
              <div class="offcanvas-footer mt-auto">
                <a
                  title="apply actions"
                  class="btn btn-dark mb-1 d-grid w-100"
                  @click="$store.dispatch('modals/toggleInfoItemSheet', false)"
                >Close</a>
              </div>
            </template>
          </ItemInfoSheet>
        </div>
        <ShowFormData
          v-if="user.enter_convo_id"
          :selected="{user_id: user.id, convo_id: user.enter_convo_id}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BCollapse, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import CalendarAndAlarms from './components/CalendarAndAlarms.vue'
import Traces from './components/Traces.vue'
import Tasks from './components/Tasks.vue'
import Timeline from './components/Timeline.vue'
import ShowFormData from '../../admin/academy-call/components/ShowFormData.vue'
import Stats from '../scientific-production-report/components/Stats.vue'
import Notifications from './components/Notifications.vue'
import Trips from './components/Trips.vue'
import FutureTrips from './components/FutureTrips.vue'

export default {
  components: {
    BCollapse,
    BDropdown,
    BDropdownItem,
    CalendarAndAlarms,
    Traces,
    Tasks,
    Timeline,
    ShowFormData,
    Stats,
    Notifications,
    Trips,
    FutureTrips,
    ItemInfoSheet,
  },
  data() {
    return {
      userId: this.$route.params.id,
      openEdit: false,
      loading: true,
      yearBetween: {},
      icon_path: '',
      hide: true,
      active: {},
    }
  },
  computed: {
    ...mapGetters({
      // user: 'auth/researcher',
      user: 'users/user',
      loggedUser: 'auth/admin',
      alerts: 'alerts/alerts',
      alertLoading: 'alerts/loading',
    }),
  },
  async mounted() {
    await this.reloadDashboard()

    if (this.user.enter_convo_id) {
      await this.$store.dispatch('convo/fetchSpecific', this.user.enter_convo_id)
    }
  },
  methods: {
    viewCV() {
      if (this.user.CVFile.length > 0) {
        window.open(this.user.CVFile.length > 0 ? this.user.CVFile[0].url : null)
      }
    },
    showEvaluation() {
      // WIP until we import the senior calls
      // if (this.user.enter_convo_id) {
      //   this.$store.dispatch('modals/toggleFormData', true)
      // }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.$toastr.success('', 'Copied to clipboard')
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    async handleFileChange(event) {
      const file = event.target.files[0]
      if (file || this.scientificPersonalInfo.avatar.length > 0) {
        this.loading = true
        if (file) {
          file.weight = (file.size / 1024 / 1024).toFixed(2)
          const newAvatar = {
            name: file.name,
            original_name: file.name,
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            file,
          }
          const userAvatar = {
            avatar: newAvatar,
            user_id: this.user.id,
          }
          await this.$store.dispatch('users/uploadAvatar', userAvatar)
          this.newAvatar = []
          this.reloadDashboard()
        }
      }
    },
    async reloadDashboard() {
      if (this.userId && this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('users/fetchUser', this.userId)
      } else if (this.$route.query.prefilterUser && this.loggedUser.roles.includes('super-admin')) {
        await this.$store.dispatch('users/fetchUser', this.$route.query.prefilterUser)
      } else {
        await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
      }

      this.icon_path = this.user ? this.user.avatarUrl : ''

      if (Array.isArray(this.user.areas)) {
        [this.user.areas] = this.user.areas
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    showAlert(alert) {
      this.active = alert
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
  },
}
</script>
